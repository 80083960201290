import { Injectable, inject } from '@angular/core';
import { ChatBackendBase as BantaBackend } from '@banta/sdk';
import { LoggerService } from '@tytapp/common';
import { isServerSide } from '@tytapp/environment-utils';
import { UserService } from '@tytapp/user';

@Injectable()
export class TYTBantaAuthentication {
    private banta = inject(BantaBackend);
    private userService = inject(UserService);
    private logger = inject(LoggerService);

    init() {
        if (isServerSide())
            return;

        this.logger.info(`[TYT/Banta]: Connecting TYT and Banta auth systems...`);

        this.userService.userChanged.subscribe(user => {
            this.banta.user = user ? {
                displayName: user.display_name,
                username: user.username,
                avatarUrl: user.profile ? user.profile.avatar : undefined,
                id: user.uuid,
                uid: user.uuid,
                token: this.userService.token
            } : null;

            if (user) {
                this.logger.info(`[TYT/Banta] Banta is logged in`);
            } else {
                this.logger.info(`[TYT/Banta] Banta is logged out`);
            }
        });
    }
}